<template>
  <div class="row feed boxw mb-1 form">
    <router-link to="/profiles">profiles</router-link> |
    <a @click="cronjobFN">cronjob</a>
    <div class="boxw">
      <h3 class="titulo">{{$t('config.configuracoes')}}</h3>
      <div class="row pd-l-20 pd-r-20 pd-b-20 pd-t-5">
        <div class="col-md-6 m-t-15">
          <label>{{$t('email')}}</label>
          <input type="email" disabled class="form-control" v-model="user.name">
        </div>
        <div class="col-md-6 m-t-15">
          <label>{{$t('username')}}</label>
          <input type="text" v-model="user.username" disabled class="form-control">
        </div>
        <div class="col-md-6 m-t-15">
          <label for="name">{{$t('name')}}</label>
          <input type="text" class="form-control" v-model="user.name" id="name">
        </div>
        <div class="col-md-6 m-t-15">
          <label class="block" for="birthdate">{{$t('birthdate')}}</label>
          <select required class="form-control aniversario1" v-model="dia">
            <option value="">{{$t('dia')}}</option>
            <option :value="dia" v-for="(dia, index) in 31" :key="index">{{dia}}</option>
          </select>
          <select required class="form-control aniversario1" v-model="mes">
            <option value="">{{$t('mes')}}</option>
            <option :value="mes" v-for="(mes, index) in 12" :key="index">{{mes}}</option>
          </select>
          <select required class="form-control aniversario1" v-model="ano">
            <option value="">{{$t('ano')}}</option>
            <option :value="ano" v-for="(ano, index) in anos" :key="index">{{ano}}</option>
          </select>
        </div>
        <div class="col-md-12 m-t-15">
          <label for="about_me">{{$t('config.about_me')}}</label>
          <textarea rows="6" id="about_me" class="form-control" v-model="user.about_me"></textarea>
        </div>

        <div class="col-md-6 m-t-15">
          <label for="sex">{{$t('sexo')}}</label>
          <select class="form-control" v-model="user.sex" id="sex">
            <option value=""></option>
            <option value="male">{{$t('male')}}</option>
            <option value="female">{{$t('female')}}</option>
            <option value="couple">{{$t('couple')}}</option>
            <option value="other">{{$t('other')}}</option>
          </select>
        </div>
        <div class="col-md-6 m-t-15">
          <label for="sexual_orientation">{{$t('config.sexual_orientation')}}</label>
          <select class="form-control" v-model="user.sexual_orientation" id="sexual_orientation">
            <option value=""></option>
            <option value="heterosexual">{{$t('heterosexual')}}</option>
            <option value="homosexual">{{$t('homosexual')}}</option>
            <option value="bisexual">{{$t('bisexual')}}</option>
            <option value="other">{{$t('other')}}</option>
          </select>
        </div>

        <div class="col-md-6 m-t-15">
          <label for="looking_for">{{$t('config.looking_for')}}</label>
          <select class="form-control" v-model="user.looking_for" id="looking_for">
            <option value=""></option>
            <option value="male">{{$t('male')}}</option>
            <option value="female">{{$t('female')}}</option>
            <option value="couple">{{$t('couple')}}</option>
            <option value="other">{{$t('other')}}</option>
            <option value="friends">{{$t('friends')}}</option>
          </select>
        </div>
        <div class="col-md-6 m-t-15">
          <label for="see">{{$t('config.see')}}</label>
          <select class="form-control" v-model="user.see" id="see">
            <option value="all">{{$t('all')}}</option>
            <option value="only_followed">{{$t('only_followed')}}</option>
            <option value="male">{{$t('male')}}</option>
            <option value="female">{{$t('female')}}</option>
            <option value="couple">{{$t('couple')}}</option>
            <option value="other">{{$t('other')}}</option>
            <option value="friends">{{$t('friends')}}</option>
          </select>
        </div>

        <div class="col-md-6 m-t-15">
          <label for="country">{{$t('country')}}</label>
          <select class="form-control" v-model="user.country" id="country">
            <option value=""></option>
          </select>
        </div>
        <div class="col-md-6 m-t-15">
          <label for="city">{{$t('city')}}</label>
          <input type="text" class="form-control" v-model="user.city" id="city">
        </div>

        <div class="col-md-6 m-t-15">
          <label for="privacy">{{$t('privacy')}}</label>
          <select class="form-control" v-model="user.privacy">
            <option value="0">{{$t('ninguem')}}</option>
            <option value="2">{{$t('privado')}}</option>
            <option value="3">{{$t('publico')}}</option>
          </select>
        </div>
        <div class="col-md-12 m-t-15">
          <button type="button" @click="submit" class="button button2 btn-block">{{$t('atualizar')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { userUpdate, cronjob } from './../resources/fn'
import { success, error } from '../resources/functions'

export default {
  name: 'config',
  data () {
    return {
      ano: {},
      mes: {},
      dia: {},
      anos: []
    }
  },
  beforeMount () {
    this.SET_LOAD()
    this.anos = []
    let ano = new Date().getFullYear() - 17
    while (--ano > 1900) {
      this.anos.push(ano)
    }
  },
  computed: {
    ...mapState({
      user: 'user'
    })
  },
  methods: {
    ...mapMutations([
      'SET_LOAD',
      'SET_USER'
    ]),
    submit () {
      this.SET_LOAD(true)
      userUpdate(this.axios, this.user).then((res) => {
        success(this, this.$t('config.update_success'))
        this.SET_USER(res.data)
        this.SET_LOAD()
      }, (res) => {
        error(this, res.error)
        this.SET_LOAD()
      })
    },
    cronjobFN () {
      this.SET_LOAD(true)
      cronjob(this.axios).then((res) => {
        success(this, 'feed atualizado com sucesso')
        this.SET_LOAD()
      }, (res) => {
        this.SET_LOAD()
      })
    }
  }
}

</script>
